import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/ceur",
    component: () => import("./views/ceur/CeurLayoutPublic.vue"),
    children: [
      {
        path: "/ceur",
        name: "ceurList",
        component: () => import("./views/ceur/CeurList.vue")
      },
      {
        path: "/ceur/ceurFaq",
        name: "ceurFaq",
        component: () => import("./views/ceur/CeurFaq.vue")
      },
      {
        path: "/claim/:id",
        name: "claim",
        component: () => import("./views/ceur/CeurClaimOcr.vue")
      },
      {
        path: "/ceur/ceurClaim/:id",
        name: "ceurClaim",
        component: () => import("./views/ceur/CeurClaimOcr.vue")
      },
      {
        path: "/ceur/ceurTrack",
        name: "ceurTrack",
        component: () => import("./views/ceur/CeurTrack.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutComingSoon.vue"),
    children: [
      {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("./views/ceur/CeurComingSoon.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/LayoutPublicFreelancer.vue"),
    children: [
      {
        path: "/ceur/promotionInvitation/:promotionId",
        name: "promotionInvitation",
        component: () => import("./views/PromotionInvitation.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("./views/ceur/CeurLayoutPublicNoMenu.vue"),
    children: [
      {
        path: "/ceur/imaging-edge",
        name: "CeurImagingEdge",
        component: () => import("./views/ceur/CeurImagingEdge.vue")
      },
      {
        path: "/ceur/photo-event",
        name: "CeurPhotoEvent",
        component: () => import("./views/ceur/CeurPromotionRegistration.vue"),
        meta: {
          promotionTypeKey: "PHOTO_EVENT"
        }
      },
      {
        path: "/ceur/imagingusa2025",
        name: "CeurImagingUsa2025",
        component: () => import("./views/ceur/CeurPromotionRegistration.vue"),
        meta: {
          promotionTypeKey: "PROMOTION_IMAGING_EDGE"
        }
      },
      {
        path: "/ceur/adobe-max",
        name: "CeurAdobeMaxPromotion",
        component: () => import("./views/ceur/CeurAdobeMaxPromotion.vue")
      },
      {
        path: "/ceur/product-registration",
        name: "claimProductRegistration",
        component: () => import("./views/ceur/CeurClaimProductRegistration.vue")
      },
      {
        path: "/landing",
        name: "ceurLanding",
        component: () => import("./views/ceur/CeurLanding.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.onError(error => {
  if (error.name === "ChunkLoadError" || /Loading chunk \d+ failed/g.test(error.message)) {
    window.location.reload();
  }
});

export default router;
