import ApiService from "./api.service";
import JwtService from "./jwt.service";

import { SELECT_FISCAL_YEAR } from "../store/client.module";
import { LOGIN, LOGOUT, UPDATE_USER } from "../store/user.module";

import { SELECT_CLIENT } from "../store/client.module";
import { SELECT_MOR_STORE } from "../store/morStore.module";
import {
  SECURITY_ANSWER_COUNT,
  SELECT_AVAILABLE_PARTICIPANTS,
  SELECT_PARTICIPANT,
  START_IMPERSONATE,
  STOP_IMPERSONATE,
  UNSELECT_AVAILABLE_PARTICIPANTS
} from "../store/participant.module";
import { SELECT_PROGRAM } from "../store/program.module";
import { UNSELECT_AVAILABLE_STORE_PROGRAMS, UNSELECT_STORE_PROGRAM } from "../store/storeProgram.module";

const AuthService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  login(username, password) {
    return JwtService.destroyToken().then(() => {
      return ApiService.post("/api/auth/login", {
        username: username,
        password: password
      }).then(({ data }) => {
        return JwtService.saveToken(data.token).then(() => {
          return this.store.dispatch(LOGIN, data);
        });
      });
    });
  },
  logout() {
    return this.store.dispatch(STOP_IMPERSONATE).then(() => {
      return this.store.dispatch(UNSELECT_AVAILABLE_PARTICIPANTS).then(() => {
        return this.store.dispatch(UNSELECT_AVAILABLE_STORE_PROGRAMS, null).then(() => {
          return this.store.dispatch(UNSELECT_STORE_PROGRAM, null).then(() => {
            return this.store.dispatch(SELECT_FISCAL_YEAR, null).then(() => {
              return this.store.dispatch(LOGOUT).then(() => {
                return JwtService.destroyToken().then(() => {
                  window.localStorage.removeItem("gapp-vuex");
                });
              });
            });
          });
        });
      });
    });
  },
  verify() {
    if (JwtService.getToken()) {
      return ApiService.get("/api/auth/verify")
        .then(({ data }) => {
          return this.store.dispatch(UPDATE_USER, data);
        })
        .catch(e => {
          console.log(e);
          return Promise.reject("Unable to verify");
        });
    } else {
      return this.logout().then(() => {
        return Promise.reject("No Auth Token");
      });
    }
  },
  isPreEnrolled() {
    let participant = this.store.getters.selectedParticipant;
    return participant && participant.status.name == "PRE";
  },
  storeSecurityAnswerCount() {
    return ApiService.get("/api/securityAnswers/getByUser").then(({ data }) => {
      return this.store.dispatch(SECURITY_ANSWER_COUNT, data.length);
    });
  },
  storeSelectParticipant(participant) {
    return this.store.dispatch(SELECT_PARTICIPANT, participant).then(() => {
      if (participant.program) {
        return this.storeSelectProgram(participant.program).then(() => {
          if (participant.program.programGroup && participant.program.programGroup.client) {
            return this.storeSelectClient(participant.program.programGroup.client).then(() => {
              return this.storeSecurityAnswerCount();
            });
          }
        });
      }
    });
  },
  applyProgramCustomFields(program) {
    return ApiService.get("/api/programs/" + program.id + "/customFields/public").then(({ data }) => {
      program.organizationCustomFields = data.organizationCustomFields;
      program.participantCustomFields = data.participantCustomFields;
      program.promotionCustomFields = data.promotionCustomFields;
      program.promotionTypeCustomFields = data.promotionTypeCustomFields;
      program.saleCustomFields = data.saleCustomFields;
      program.saleProductCustomFields = data.saleProductCustomFields;
      program.claimProductCustomFields = data.claimProductCustomFields;
      program.promotionInvitationCustomFields = data.promotionInvitationCustomFields;
      program.payoutCustomFields = data.payoutCustomFields;
      return program;
    });
  },
  storeSelectProgram(program) {
    // Fetch any lazy relationships
    return this.applyProgramCustomFields(program).then(() => {
      return this.store.dispatch(SELECT_PROGRAM, program).then(() => {
        if (program.programType.name == "MOR") {
          return ApiService.get("/api/morStores")
            .then(({ data }) => {
              return this.storeSelectMorStore(data);
            })
            .catch(() => {
              console.log("MOR Store not found");
              // We need to make sure that we are clearing the MOR Store
              return this.storeSelectMorStore(null);
            });
        } else {
          return Promise.resolve();
        }
      });
    });
  },
  storeSelectMorStore(morStore) {
    return this.store.dispatch(SELECT_MOR_STORE, morStore);
  },
  storeSelectClient(client) {
    return this.store.dispatch(SELECT_CLIENT, client);
  },
  storeAvailableParticipants() {
    return ApiService.get("/api/participants/byCurrentUserClientKeyProgramGroupKey?sort=program.name,ASC").then(
      ({ data }) => {
        let participants = data.content
          .filter(participant => participant.status.name != "CAN")
          .filter(participant => participant.program.effective);
        this.store.dispatch(SELECT_AVAILABLE_PARTICIPANTS, participants);
      }
    );
  },
  startImpersonation(participant) {
    return this.store.dispatch(START_IMPERSONATE, participant).then(() => {
      return this.storeSecurityAnswerCount().then(() => {
        return this.storeAvailableParticipants(participant.user);
      });
    });
  },
  stopImpersonation() {
    if (this.isImpersonating()) {
      return this.store.dispatch(STOP_IMPERSONATE).then(() => {
        return this.storeAvailableParticipants().then(() => {
          return this.storeSecurityAnswerCount().then(() => {
            return Promise.resolve();
          });
        });
      });
    } else {
      return Promise.resolve();
    }
  },
  isImpersonating() {
    let isImpersonationParticipantIsDefined = typeof this.store.getters.impersonationParticipant !== "undefined";
    let isImpersonationParticipantIdIsDefined = typeof this.store.getters.impersonationParticipant.id !== "undefined";
    let isDefined = isImpersonationParticipantIsDefined && isImpersonationParticipantIdIsDefined;
    return isDefined;
  }
};

export default AuthService;
